<template>
  <div class="orderlist">
    <div style="text-align: left;"> <Head title="我的订单"></Head></div>
    <div class="el">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 订单列表 -->
        <el-tab-pane label="订单列表" name="first">
          <!-- 输入框 -->
          <el-form
              :model="searchInput"
              size="small"
              inline
              status-icon
              label-width="140px"
              class="search-input"
          >
            <!-- 输入框 -->
            <!--          <el-row>-->
            <!--            <el-col :span="6">-->
            <!--              <el-input-->
            <!--                v-model="searchInput.productName"-->
            <!--                placeholder="请输入商品名称"-->
            <!--                clearable-->
            <!--                type="text"-->
            <!--              />-->
            <!--            </el-col>-->
            <!--            <el-col :span="4" style="text-align: left">-->
            <!--              <el-button-->
            <!--                type="primary"-->
            <!--                size="small"-->
            <!--                style="font-size: 13px; line-height: 17px"-->
            <!--                @click="searchBtn"-->
            <!--                >搜索</el-button-->
            <!--              >-->
            <!--            </el-col>-->
            <!--          </el-row>-->
          </el-form>
          <el-table
              :data="tableData"
              style="width: 100%"
              border
              :header-cell-style="{ background: '#f5f5f5' }"
          >
            <el-table-column type="index" width="50" label="序号" />
            <el-table-column prop="orderId" label="订单号"> </el-table-column>
            <el-table-column prop="productName" label="商品名称">
            </el-table-column>
            <el-table-column label="支付金额(元)">
              <template slot-scope="scope">
                <span class="red">¥{{ scope.row.orderPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createAt" label="下单时间"> </el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <el-tag>{{ scope.row.statusName }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <div class="btn-small">
                  <el-button
                      type="primary"
                      size="mini"
                      @click="handleOrderDetail(scope.row)"
                  >查看详情
                  </el-button>
                </div>
                <div v-if="scope.row.status === 1" class="btn-small">
                  <el-button
                      type="info"
                      size="mini"
                      @click="handleCancel(scope.row.orderId)"
                  >取消订单
                  </el-button>
                </div>
                <div v-if="scope.row.refundFlag === 1  && scope.row.status !== 6" class="btn-small">
                  <el-button
                      type="warning"
                      size="mini"
                      @click="handleRefund(scope.row.orderId)"
                  >申请退款
                  </el-button>
                </div>
                <div v-if="scope.row.userComment !== undefined" class="btn-small">
                  <el-button
                      type="success"
                      size="mini"
                      @click="handleComment(scope.row.userComment)"
                  >查看评价
                  </el-button>
                </div>
                <div v-if="scope.row.userComment === undefined" class="btn-small">
                  <el-button
                      type="success"
                      size="mini"
                      @click="commentOrder(scope.row.orderId)"
                  >评价订单
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <Pagination
                :total="search.total"
                :page.sync="search.page"
                :limit.sync="search.size"
                @pagination="getData"
            />
          </div>
        </el-tab-pane>
        <!-- 退款 -->
        <el-tab-pane label="退款管理" name="second">
          <!-- 输入框 -->
          <el-form
              :model="searchInput"
              size="small"
              inline
              status-icon
              label-width="140px"
              class="search-input"
          >
            <!-- 输入框 -->
            <!--          <el-row>-->
            <!--            <el-col :span="6">-->
            <!--              <el-input-->
            <!--                v-model="searchInput.productName"-->
            <!--                placeholder="请输入商品名称"-->
            <!--                clearable-->
            <!--                type="text"-->
            <!--              />-->
            <!--            </el-col>-->
            <!--            <el-col :span="4" style="text-align: left">-->
            <!--              <el-button-->
            <!--                type="primary"-->
            <!--                size="small"-->
            <!--                style="font-size: 13px; line-height: 17px"-->
            <!--                @click="searchBtn2"-->
            <!--                >搜索</el-button-->
            <!--              >-->
            <!--            </el-col>-->
            <!--          </el-row>-->
          </el-form>
          <span style="font-size: 8px;color: red">退款金额在退款审核通过后15日内退回支付账户！</span>
          <el-table
              :data="tableData2"
              style="width: 100%"
              border
              :header-cell-style="{ background: '#f5f5f5' }"
          >
            <el-table-column type="index" width="50" label="序号" />
            <el-table-column prop="orderId" label="订单号"> </el-table-column>
            <el-table-column prop="productName" label="商品名称">
            </el-table-column>
            <el-table-column label="退款金额/(元)">
              <template slot-scope="scope">
                <span class="red">¥{{ scope.row.refundAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createAt" label="申请时间"> </el-table-column>
            <el-table-column label="审核状态">
              <template slot-scope="scope">
                <el-tag>{{ scope.row.auditStatusName }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <div class="btn-small">
                  <el-button
                      v-if="scope.row.auditStatus === 3"
                      type="primary"
                      size="mini"
                      @click="handleAuditDetail(scope.row)"
                  >审核意见
                  </el-button>
                </div>
                <div v-if="scope.row.auditStatus === 1" class="btn-small">
                  <el-button
                      type="warning"
                      size="mini"
                      @click="handleCancelRefund(scope.row.refundId)"
                  >取消退款申请
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <Pagination
                :total="search2.total"
                :page.sync="search2.page"
                :limit.sync="search2.size"
                @pagination="getRefundData"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 订单详情弹窗 -->
      <OrderDetailDialog
          v-if="orderDetailDialog.show"
          :order-detail-dialog="orderDetailDialog"
      />

      <el-dialog title="审核意见" :visible.sync="visible" width="600px">
        <el-form>
          <el-form-item>
            <el-input type="textarea" rows="4" disabled v-model="auditRemark"></el-input>
          </el-form-item>
        </el-form>
        <div class="tip">审核失败，如需再次申请请返回订单列表</div>
      </el-dialog>

      <el-dialog title="订单评价" :visible.sync="commentVisible" width="600px">
        <el-form>
          <el-form-item>
            <el-input type="textarea" rows="4" disabled v-model="comment"></el-input>
          </el-form-item>
        </el-form>
        <div class="tip">感谢您对订单的评价！</div>
      </el-dialog>

      <el-dialog title="评价订单" :visible.sync="commentOrderVisible" width="600px">
        <el-form>
          <el-form-item>
            <el-input type="textarea" rows="4" v-model="comment"></el-input>
          </el-form-item>
        </el-form>
        <!-- 确认按钮  -->
        <div class="btn-wrap">
          <el-button type="primary" @click="commentOrderBtn">评价</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getOrderList,
  getRefundList,
  cancelOrder,
  applyRefund,
  cancelApplyRefund,
  commentOrder,
} from "@/api/order";
import Pagination from "@/components/Pagination";
import OrderDetailDialog from "./components/OrderDetailDialog";
import Head from './Head'
export default {
  name: "Orderlist",
  components: {
    Pagination,
    OrderDetailDialog,
    Head
  },
  data() {
    return {
      visible: false,
      auditRemark: "",
      activeName: "first", //选中状态
      productName: [],
      commentVisible: false,
      commentOrderVisible: false,
      comment: "",
      commentOrderId: null,
      searchInput: {}, //搜索表单
      state: "",
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      search2: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      orderDetailDialog: {},
      tableData: [],
      tableData2: [],
    };
  },
  mounted() {
    this.getData();
    // this.getRefundData()
  },
  methods: {
    //获取订单数据
    getData() {
      getOrderList(this.search).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            const { data } = res.data;
            this.search.total = data.total; // 共几条数据
            this.tableData = data.rows; // 绑定数据
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    handleComment(comment) {
      this.commentVisible = true;
      this.comment = comment;
    },
    commentOrder(orderId) {
      this.commentOrderVisible = true;
      this.commentOrderId = orderId;
      this.comment = "";
    },
    commentOrderBtn() {
      if (!this.comment) {
        this.$message({
          type: "error",
          message: "评价内容不能为空！",
        });
      }
      commentOrder({
        orderId: this.commentOrderId,
        userComment: this.comment,
      }).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.$message({
            type: "success",
            message: res.data.message,
          });
          this.commentOrderVisible = false;
          this.comment = "";
          this.getData();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 获取退款订单
    getRefundData() {
      getRefundList(this.search2).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            const { data } = res.data;
            this.search2.total = data.total; // 共几条数据
            this.tableData2 = data.rows; // 绑定数据
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    handleCancel(orderId) {
      cancelOrder({ orderId: orderId }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            // const { data} = res.data;
            // this.txToken = data.txToken;
            this.$message({
              type: "success",
              message: "取消订单成功",
            });
          } else {
            this.$message({
              type: "error",
              message: "取消订单失败",
            });
          }
        }
      });
    },
    handleAuditDetail(row) {
      this.auditRemark = row.remark
      this.visible = true;
    },
    handleOrderDetail(row) {
      this.orderDetailDialog.show = true;
      this.orderDetailDialog = {
        show: true,
        title: "订单详情",
        orderId: row.orderId,
        // refundFlag: row.refundFlag
      };
    },
    searchBtn() {
      if (!this.searchInput.productName) {
        this.$message({
          type: "error",
          message: "商品名称不能为空！",
        });
      } else {
        this.search = Object.assign(this.search, {
          productName: this.searchInput.productName,
          // refund: false,
        });
        this.getData();
      }
    },
    searchBtn2() {
      if (!this.searchInput.productName) {
        this.$message({
          type: "error",
          message: "商品名称不能为空！",
        });
      } else {
        this.search = Object.assign(this.search, {
          productName: this.searchInput.productName,
          // refund: true,
        });
        this.getData();
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name === "second") {
        // 退款订单
        /* this.search = Object.assign(this.search, {
          refund: true,
        }); */
        this.getRefundData();
      } else {
        this.search = Object.assign(this.search, {
          // refund: false,
        });
        this.getData();
      }
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    //申请退款
    handleRefund(orderId) {
      applyRefund({ orderId: orderId }).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.$message({
            type: "success",
            message: res.data.message,
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //取消退款申请
    handleCancelRefund(refundId) {
      cancelApplyRefund({ refundId: refundId }).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.$message({
            type: "success",
            message: res.data.message,
          });
          this.getRefundData();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.orderlist {
  background: #fff;
  // span {
  //   margin-left: 15px;
  // }
  em {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .el-table {
    margin-top: 10px;
  }
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
  .btn-small {
    margin-bottom: 10px;
  }
  .el-button {
    margin-left: 15px;
  }
  .pagination {
    margin-top: 30px;
  }
  .red {
    color: red;
    font-weight: bold;
  }
}
.el{
  padding: 10px 20px;
  margin-top: 0px !important;
  // min-height: 62vh;
}
</style>