<template>
  <el-dialog
    v-if="orderDetailDialog.show"
    :title="orderDetailDialog.title"
    :visible.sync="orderDetailDialog.show"
    center
    width="600px"
    top="30vh"
  >
    <div class="cont">
      <el-row>
        <el-col :span="6"><div class="l-title">订单ID：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont">{{ info.orderId }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"><div class="l-title">商品名称：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont">{{ info.productName }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"><div class="l-title">商品价格：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont red">¥{{ info.productPrice }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"><div class="l-title">订单状态：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont">{{ info.statusName }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"><div class="l-title">下单时间：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont">{{ info.createAt }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"><div class="l-title">支付方式：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont">{{ info.payName }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"><div class="l-title">支付流水号：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont">{{ info.sn }}</div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="6"><div class="l-title">支付金额：</div></el-col>
        <el-col :span="18"
          ><div class="r-cont red">¥{{ info.payPrice }}</div></el-col
        >
      </el-row>
      <div class="btn-wrap">
        <el-button @click="goBack" type="primary">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getOrderDetail } from "@/api/order";
export default {
  name: "OrderDetailDialog",
  props: {
    orderDetailDialog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      info: {}, // 订单信息
    };
  },
  mounted() {
    console.log("ccc...");
    this.getData(this.orderDetailDialog.orderId);
  },
  methods: {
    getData(orderId) {
      getOrderDetail(orderId).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            const { data } = res.data;
            this.info = data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
          console.log(this.info);
        }
      });
    },
    goBack() {
      this.orderDetailDialog.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.cont {
  margin: 0px auto 0;
  .l-title {
    margin: 10px 0;
    text-align: left;
    padding-left: 50px;
    color: #666;
  }
  .r-cont {
    color: #333;
    margin: 10px 0;
    text-align: left;
    // padding-left: 10px;
  }
  .red {
    color: red;
    font-weight: bold;
  }
  .btn-wrap {
    margin-top: 20px;
    text-align: center;
  }
}
</style>